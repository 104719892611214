import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PrivateRoute from "./script/pages/Routes/PrivateRoute";
import { NetworkDetector } from "./YComponents";
import AuthWrap from "./script/pages/Routes/AuthWrap";
import { YContext } from "./script/Context/YContext";

import Login from "./script/Signup/Login";
import DoctorUserSignup from "./script/Signup/DoctorUserSignup";
import HospitalUserSignup from "./script/Signup/HospitalUserSignup";
import VendorUserSignup from "./script/Signup/VendorUserSignup";
import StudentUserSignup from "./script/Signup/StudentUserSignup";
import StaffUserSignup from "./script/Signup/StaffUserSignup";
import MedicalAssociationUserSignup from "./script/Signup/MedicalAssociationUserSignup";

import Buynew from "./script/marketplace/BuyNew/Buynew";
import BuyUsed from "./script/marketplace/BuyUsed/BuyUsed";
import BuyMedicalInstrument from "./script/marketplace/BuyMedicalInstrument/BuyMedicalInstrument";
import AnnualContracts from "./script/marketplace/AnnualContracts/AnnualContracts";
// import ProfilePage from "./script/Profile/ProfilePage";
import Doctorprofile from "./script/Profile/Doctor/Doctorprofile";
import Hospitalprofile from "./script/Profile/Hospital/Hospitalprofile";
import Vendorprofile from "./script/Profile/Vendor/Vendorprofile";
import Sellcontractpage from "./script/marketplace/SellContract/Sellcontractpage";
import ProductDetails from "./script/marketplace/Components/ProductDetails";
// import Annualproductdetails from "./script/marketplace/AnnualContracts/Annualproductdetails";
import AnnualContractDetails from "./script/marketplace/AnnualContracts/AnnualContractDetails";
import NewProductLaunchDetails from "./script/marketplace/ProductCatelog/NewProductLaunchDetails";
import Notification from "./script/Notification/Notification";
import Packages from "./script/Account/Packages/Pricecard";
import Inventorypage from "./script/Account/Inventory/Inventorypage";
import Verification from "./script/Verification/Verification";
import Sell from "./script/marketplace/SellProduct/Sell";
import Packagedetails from "./script/Account/Packages/Packagedetails";
import Updatesellcontract from "./script/marketplace/SellContract/Updatesellcontract";
import Updatesellproduct from "./script/marketplace/SellProduct/Updatesellproduct";
import UpdateNewProductLaunch from "./script/marketplace/ShowCaseProduct/UpdateNewProductLaunch";
// import ChatPage from "./script/ChatScreen/ChatPage";
// import ChatPage from "./script/Chat/ChatPage";
import HomeScreen from "./script/HomePageScreen/HomeScreen";
import ReferPatient from "./script/Referral/ReferPatient/ReferPatient";
import ReferralDashboardPage from "./script/Referral/ReferralDashboard/ReferralDashboardPage";
import StoryBoardPage from "./script/StoryBoard/StoryBoardPage";
import PostAJob from "./script/Jobs/PostAJob/PostAJob";
import PostAnObservership from "./script/Observerships/PostAnObservership/PostAnObservership";
import AllJobsCards from "./script/Jobs/ApplyForJob/AllJobsCards";
import JobDetails from "./script/Jobs/ApplyForJob/JobDetails";
import MyJobApplications from "./script/Jobs/MyJobApplications/MyJobApplications";
import MyObservershipApplications from "./script/Observerships/MyObservershipApplications/MyObservershipApplications";
// import AllEventCards from "./script/EventSection/UpcomingEvents/AllEventCards";
import ManageJobApplications from "./script/Jobs/ManageJobs/ManageJobApplications";
import ManageObservershipApplications from "./script/Observerships/ManageObserverships/ManageObservershipApplications";
import EditPostAJob from "./script/Jobs/ManageJobs/EditPostAJob";
import EditPostAnObservership from "./script/Observerships/ManageObserverships/EditPostAnObservership";
import AllEventPasses from "./script/EventSection/EventPass/AllEventPasses";
import AllQueAndAnsPage from "./script/Miscellaneous/QAndA/AllQueAndAnsPage";
import AllAnswers from "./script/Miscellaneous/QAndA/AllAnswers";
import AllObservershipCards from "./script/Observerships/ApplyForObservership/AllObservershipCards";
import ObservershipDetails from "./script/Observerships/ApplyForObservership/ObservershipDetails";
import OrganizeEvent from "./script/EventSection/OrganizeEvent/OrganizeEvent";
import OrganizeTraining from "./script/Training/OrganizeTraining/OrganizeTraining";
import AttendTraining from "./script/Training/AttendTraining/AttendTraining";
import ViewUserProfile from "./script/Profile/ViewProfile/ViewUserProfile";
import ReferredToMe from "./script/Referral/ReferralDashboard/ReferredToMe";
import ReferredByMe from "./script/Referral/ReferralDashboard/ReferredByMe";
import ShowCaseProduct from "./script/marketplace/ShowCaseProduct/ShowCaseProduct";
import BuyCategory from "./script/marketplace/Components/BuyCategory";
import BuySubCategory from "./script/marketplace/Components/BuySubCategory";
import NewProductCatelog from "./script/marketplace/ProductCatelog/NewProductCatelog";
import NewProductCards from "./script/marketplace/ProductCatelog/NewProductCards";
import SearchUser from "./script/Miscellaneous/Search/SearchUser";
import ManageTrainingCards from "./script/Training/ManageTrainings/ManageTrainingCards";
import EditOrganizeTraining from "./script/Training/ManageTrainings/EditOrganizeTraining";
import ViewParticipantsFromUpcomingTraining from "./script/Training/ManageTrainings/ViewParticipantsFromUpcomingTraining";
import ViewParticipantsFromArchiveTraining from "./script/Training/ManageTrainings/ViewParticipantsFromArchiveTraining";
import ManageEvents from "./script/EventSection/ManageEvents/ManageEvents";
import EditOrganizeEvent from "./script/EventSection/ManageEvents/EditOrganizeEvent";
import ViewParticipantsFromUpcomingEvent from "./script/EventSection/ManageEvents/ViewParticipantsFromUpcomingEvent";
import ViewParticipantsFromArchiveEvent from "./script/EventSection/ManageEvents/ViewParticipantsFromArchiveEvent";
import ViewApplicantsFromJob from "./script/Jobs/ManageJobs/ViewApplicantsFromJob";
import ViewApplicantsFromObservership from "./script/Observerships/ManageObserverships/ViewApplicantsFromObservership";
import PostAMagazine from "./script/Magazine/PostAMagazine/PostAMagazine";
import ViewMagazine from "./script/Magazine/ViewMagazine/ViewMagazine";
import MedicalAssociationYear from "./script/Magazine/ViewMagazine/MedicalAssociationYear";
import AllMagazines from "./script/Magazine/ViewMagazine/AllMagazines";
import ManageMagazines from "./script/Magazine/ManageMagazine/ManageMagazines";
import StudentProfile from "./script/Profile/Student/StudentProfile";
import MedicalStaffProfile from "./script/Profile/MedicalStaff/MedicalStaffProfile";
import MedicalAssociationProfile from "./script/Profile/MedicalAssociation/MedicalAssociationProfile";
import ShowInterestedList from "./script/Account/Inventory/ShowInterestedList";
import "./firebase";
import ComingSoon from "./script/ChatScreen/ComingSoon";
import DeactivateAccount from "./script/Navigation/DeactivateAccount";
import useFirebaseMessaging from "./script/utils/useFirebaseMessaging";
import UploadShort from "./script/Shorts/UploadShort/UploadShort";
import ShortsFeed from "./script/Shorts/ViewShorts/ShortsFeed";
import ShortPage from "./script/Shorts/ViewShorts/ShortPage";
import CreateEvent from "./script/Events/CreateEvents/CreateEvent";
import AllEventCards from "./script/Events/UpcomingEvents/AllEventCards";
import EventDetails from "./script/Events/UpcomingEvents/EventDetails";



function App() {
  const { showSubscription,
    setShowSubscription,
  } = useContext(YContext);
  useFirebaseMessaging();
  return (

    <AuthWrap>
      {/* {showSubscription && (
        <Subscription
          showSubscription={showSubscription}
          setShowSubscription={setShowSubscription}
        ></Subscription>
      )} */}
      <Router>
        <Switch>
          {/* Public Routes (Accessible to everyone) */}
          {/* Registration  */}
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/doctorsignup" component={DoctorUserSignup} />
          <Route exact path="/vendorsignup" component={VendorUserSignup} />
          <Route exact path="/hospitalsignup" component={HospitalUserSignup} />
          <Route exact path="/studentsignup" component={StudentUserSignup} />
          <Route exact path="/staffsignup" component={StaffUserSignup} />
          <Route exact path="/medicalassociationsignup" component={MedicalAssociationUserSignup} />

          {/* Public route for the shared short URL */}
          <Route exact path="/short/:shortId" component={ShortPage} />


          {/* Private Routes (Accessible only to authenticated users) */}
          {/* After Completing Registration Main Screen */}

          {/* <PrivateRoute path="/profilepage" component={ProfilePage} /> */}
          <PrivateRoute path="/doctorprofile" component={Doctorprofile} />
          <PrivateRoute path="/hospitalprofile" component={Hospitalprofile} />
          <PrivateRoute path="/vendorprofile" component={Vendorprofile} />
          <PrivateRoute path="/sellcontract" component={Sellcontractpage} />
          <PrivateRoute path="/notification" component={Notification} />
          <PrivateRoute path="/packages" component={Packages} />
          {/* <PrivateRoute path="/chat" component={ChatPage} /> */}
          <PrivateRoute path="/inventory" component={Inventorypage} />
          <PrivateRoute path="/verification" component={Verification} />
          <PrivateRoute path="/home" component={HomeScreen} />
          <PrivateRoute path="/buynew" component={Buynew} />
          <PrivateRoute path="/buyused" component={BuyUsed} />
          <PrivateRoute path="/buymedicalinstrument" component={BuyMedicalInstrument} />
          <PrivateRoute path="/annualcontracts" component={AnnualContracts} />
          <PrivateRoute path="/referpatient" component={ReferPatient} />
          <PrivateRoute path="/referraldashboard" component={ReferralDashboardPage} />
          <PrivateRoute path="/storyboard" component={StoryBoardPage} />
          <PrivateRoute path="/productdetails/:productId" component={ProductDetails} />
          <PrivateRoute path="/contractdetails/:contractId" component={AnnualContractDetails} />
          <PrivateRoute path="/newproductlaunchdetails/:newProductLaunchId" component={NewProductLaunchDetails} />
          <PrivateRoute path="/showinteresteduser/:interestId" component={ShowInterestedList} />
          <PrivateRoute path="/sell" component={Sell} />
          <PrivateRoute path="/packagedetails" component={Packagedetails} />
          <PrivateRoute path="/editsellcontract" component={Updatesellcontract} />
          <PrivateRoute path="/editsellproduct" component={Updatesellproduct} />
          <PrivateRoute path="/editnewproductlaunch" component={UpdateNewProductLaunch} />
          <PrivateRoute path="/postajobs" component={PostAJob} />
          <PrivateRoute path="/postanobservership" component={PostAnObservership} />
          <PrivateRoute path="/alljobs" component={AllJobsCards} />
          <PrivateRoute path="/jobdetails/:jobApplicationID" component={JobDetails} />
          <PrivateRoute path="/managejobapplications" component={ManageJobApplications} />
          <PrivateRoute path="/manageobservershipapplications" component={ManageObservershipApplications} />
          <PrivateRoute path="/editpostajob" component={EditPostAJob} />
          <PrivateRoute path="/editpostanobservership" component={EditPostAnObservership} />
          {/* <PrivateRoute path="/allevents" component={AllEventCards} /> */}
          <PrivateRoute path="/alleventpasses" component={AllEventPasses} />
          <PrivateRoute path="/q&a" component={AllQueAndAnsPage} />
          <PrivateRoute path="/answers" component={AllAnswers} />
          <PrivateRoute path="/myjobapplications" component={MyJobApplications} />
          <PrivateRoute path="/myobservershipapplications" component={MyObservershipApplications} />
          <PrivateRoute path="/allobservership" component={AllObservershipCards} />
          <PrivateRoute path="/observershipdetails/:observershipApplicationID" component={ObservershipDetails} />
          <PrivateRoute path="/organizeevent" component={OrganizeEvent} />
          <PrivateRoute path="/organizetraining" component={OrganizeTraining} />
          <PrivateRoute path="/attendtraining" component={AttendTraining} />
          <PrivateRoute path="/viewprofile" component={ViewUserProfile} />
          <PrivateRoute path="/referredtome" component={ReferredToMe} />
          <PrivateRoute path="/referredbyme" component={ReferredByMe} />
          <PrivateRoute path="/showcaseproduct" component={ShowCaseProduct} />
          <PrivateRoute path="/productcategory" component={BuyCategory} />
          <PrivateRoute path="/productsubcategory" component={BuySubCategory} />
          <PrivateRoute path="/newproductcatelog" component={NewProductCatelog} />
          <PrivateRoute path="/allnewproductcatelog" component={NewProductCards} />
          <PrivateRoute path="/searchuser" component={SearchUser} />
          <PrivateRoute path="/managetraining" component={ManageTrainingCards} />
          <PrivateRoute path="/editorganizetraining" component={EditOrganizeTraining} />
          <PrivateRoute path="/viewparticipants" component={ViewParticipantsFromUpcomingTraining} />
          <PrivateRoute path="/viewparticipantsfromarchive" component={ViewParticipantsFromArchiveTraining} />
          <PrivateRoute path="/manageevents" component={ManageEvents} />
          <PrivateRoute path="/editorganizeevent" component={EditOrganizeEvent} />
          <PrivateRoute path="/viewparticipantsfromevent" component={ViewParticipantsFromUpcomingEvent} />
          <PrivateRoute path="/viewparticipantsfromarchiveevent" component={ViewParticipantsFromArchiveEvent} />
          <PrivateRoute path="/viewapplicantsfromjob/:jobApplicantsId" component={ViewApplicantsFromJob} />
          <PrivateRoute path="/viewapplicantsfromobservership/:observershipApplicantsId" component={ViewApplicantsFromObservership} />
          <PrivateRoute path="/postamagazine" component={PostAMagazine} />
          {/* <PrivateRoute path="/viewmagazine" component={ViewMagazine} /> */}
          <PrivateRoute path="/viewmagazineyearwise" component={MedicalAssociationYear} />
          <PrivateRoute path="/allmagazines" component={AllMagazines} />
          <PrivateRoute path="/managemagazines" component={ManageMagazines} />
          <PrivateRoute path="/studentprofile" component={StudentProfile} />
          <PrivateRoute path="/medicalstaffprofile" component={MedicalStaffProfile} />
          <PrivateRoute path="/medicalassociationprofile" component={MedicalAssociationProfile} />

          <PrivateRoute path="/comingsoon" component={ComingSoon} />
          <PrivateRoute path="/deactivateaccount" component={DeactivateAccount} />
          <PrivateRoute path="/uploadshort" component={UploadShort} />
          <PrivateRoute path="/shortsfeed" component={ShortsFeed} />
          <PrivateRoute path="/createevent" component={CreateEvent} />
          <PrivateRoute path="/allevents" component={AllEventCards} />
          <PrivateRoute path="/eventdetails" component={EventDetails} />

          <Route component={NoMatchPage} />
        </Switch>
      </Router>
    </AuthWrap>
  );
}

export default NetworkDetector(App);

const NoMatchPage = () => {
  return (
    <>
      <h3>404 - Not found</h3>
      <Link to="/">Go to Home Screen</Link>
    </>
  );
};
